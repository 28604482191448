import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { getLocalizedValue } from '@features/drivers/data';
import { getOnboardingCashback } from '@features/onboarding/data';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, combineLatestWith, filter, Observable } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { FEATURE_FLAGS } from 'src/app/presentation/shared/constants';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { OnboardingFeature } from '../../../../core/domain/feature-attributes.model';
import { GetOnboardingFeatureUseCase } from '../../../../core/usecases/get-onboarding-feature-usecase';
import { GetOnboardingOrdersTrackingUseCase } from '../../../../core/usecases/get-onboarding-orders-tracking-usecase';
import { BaseComponent } from '../../../shared/components/BaseComponent';
import { ONBOARDING_MAX_ORDER_PROGRESS } from '../../../shared/constants/onboarding';
import { TooltipDirective } from '../../../shared/directives/tooltip/tooltip.directive';
import { SharedProgressBarComponent } from '../../shared/shared-progress-bar/shared-progress-bar.component';
import { onboardingLogToMixpanelUtility } from '../../shared/utilities/onboarding-log-to-mixpanel.utility';
import { OnboardingStoryGuideStateManager } from '../../state-manager/onboarding-story-guide.state-manager';
import {
  SelectCurrentActiveUserStepIndex,
  SelectRewardTypeForUse,
  SelectUserOnboardingCompletionStatus,
} from '../../state-manager/selectors/selector-names';
import { OnboardingCurrentGoalProgressMapper } from './mappers/onboarding-current-goal-progress-mapper';
import { OnboardingCurrentGoalProgressState } from './models/onboarding-current-goal-progress.state';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const _cio: any;

@Component({
  selector: 'app-onboarding-current-goal-progress',
  templateUrl: './onboarding-current-goal-progress.component.html',
  styleUrls: ['./onboarding-current-goal-progress.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TooltipDirective,
    NgClass,
    SharedProgressBarComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class OnboardingCurrentGoalProgressComponent extends BaseComponent implements OnInit {
  @ViewChild('wrapperElementTemplate', { static: false, read: ViewContainerRef })
  public wrapperElementTemplateRef: ViewContainerRef;

  @ViewChild('viewOrderingGoalsTemplate', { static: false, read: ViewContainerRef })
  public viewOrderingGoalsTemplateRef: ViewContainerRef;

  /**
   * Select the current active step
   */
  public currentActiveStepSelector$: Observable<number>;

  /**
   * Select the reward type for the user
   */
  public userRewardType$: Observable<string>;

  /**
   * An index to identify this step in the onboarding steps
   */
  public stepIndex: number;

  public amount = getOnboardingCashback();

  /**
   * Tooltip component reference
   */
  public tooltipComponentRef = 'OnboardingNextStepTooltipComponent';

  public onboardingFeature: OnboardingFeature;

  @Output() toggleCurrentGoalProgressCard: EventEmitter<boolean> = new EventEmitter<boolean>();

  public assetsPath = '../../../../../assets/img/on-boarding/';

  public isMoneyBack = false;

  public isBadge = false;

  public allGoals = 0;

  public currentCurrency: string;

  public isOnboardingV2Enabled = false;

  /**
   * State where the UI bind to
   */
  public state: OnboardingCurrentGoalProgressState;

  constructor(
    private _getOnboardingFeatureUseCase: GetOnboardingFeatureUseCase,
    private _getOnboardingOrdersTrackingUseCase: GetOnboardingOrdersTrackingUseCase,
    private _currentGoalProgressMapper: OnboardingCurrentGoalProgressMapper,
    private _onboardingStoryGuideStateManager: OnboardingStoryGuideStateManager,
    private _changeDetectorRef: ChangeDetectorRef,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _multitenancyService: MultitenancyService,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
  ) {
    super();
    this.currentActiveStepSelector$ = this._onboardingStoryGuideStateManager.selectStatePiece(
      SelectCurrentActiveUserStepIndex,
    );
    this.userRewardType$ =
      this._onboardingStoryGuideStateManager.selectStatePiece(SelectRewardTypeForUse);
    this.currentCurrency = getLocalizedValue(
      this._multitenancyService.getCurrentCountry().currency,
    );
  }

  ngOnInit(): void {
    this.checkOnboardingV2();
    this._getOnboardingFeatureUseCase
      .execute()
      .pipe(combineLatestWith(this._getOnboardingOrdersTrackingUseCase.execute()))
      .pipe(
        this.takeUntilIsDestroyed(),
        filter(
          ([onboardingFeature, ordersTracking]) =>
            ordersTracking.orders.length <= ONBOARDING_MAX_ORDER_PROGRESS,
        ),
      )
      .subscribe({
        next: ([onboardingFeature, ordersTracking]) => {
          this.onboardingFeature = onboardingFeature;
          this.isMoneyBack = onboardingFeature?.rewardType === 'cashback';
          this.isBadge = onboardingFeature?.rewardType === 'badge';
          this.allGoals = ONBOARDING_MAX_ORDER_PROGRESS;
          this.state = this._currentGoalProgressMapper.map(
            ordersTracking,
            this.onboardingFeature.rewardType,
            ONBOARDING_MAX_ORDER_PROGRESS,
          );
          const eventName = 'onboarding_order_tracking_banner_load';
          const payload = {
            reward_type: this.onboardingFeature.rewardType,
            progress_score: `${ordersTracking.orders.length} of ${ONBOARDING_MAX_ORDER_PROGRESS}`,
          };
          onboardingLogToMixpanelUtility(
            this._logMixpanelEventUseCase,
            eventName,
            () => window.innerWidth >= 992,
            payload,
          );
          if (typeof _cio !== 'undefined') {
            _cio.track(eventName, payload);
          }
          this.isVisible = this.state.currentScore !== ONBOARDING_MAX_ORDER_PROGRESS;
          this._changeDetectorRef.detectChanges();
        },
      });
    this._listenOnWhetherWeNeedToToggleCurrentGoalProgressCard();
  }

  checkOnboardingV2() {
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.ONBOARDING_V2).subscribe({
      next: (flag) => {
        this.isOnboardingV2Enabled = flag;
      },
    });
  }

  openCurrentGoalProgressCard() {
    const eventName = 'onboarding_order_tracking_banner_view_progress_click';
    const payload = {
      reward_type: this.onboardingFeature.rewardType,
      progress_score: `${this.state.currentScore} من ${this.state.maxScore}`,
    };
    this._logMixpanelEventUseCase.execute({ eventName, payload });
    if (typeof _cio !== 'undefined') {
      _cio.track(eventName, payload);
    }
    this.toggleCurrentGoalProgressCard.emit(true);
  }

  private _listenOnWhetherWeNeedToToggleCurrentGoalProgressCard(): void {
    combineLatest({
      currentOnboardingStep: this._onboardingStoryGuideStateManager.selectStatePiece(
        SelectCurrentActiveUserStepIndex,
      ),
      onboardingCompletionStatus: this._onboardingStoryGuideStateManager.selectStatePiece(
        SelectUserOnboardingCompletionStatus,
      ),
    })
      .pipe(this.takeUntilIsDestroyed())
      .subscribe({
        next: (observerObject) => {
          if (
            !observerObject.onboardingCompletionStatus &&
            ((this.isOnboardingV2Enabled && observerObject.currentOnboardingStep === 3) ||
              (!this.isOnboardingV2Enabled && observerObject.currentOnboardingStep === 4))
          ) {
            this.toggleCurrentGoalProgressCard.emit(true);
          } else {
            this.toggleCurrentGoalProgressCard.emit(false);
          }
        },
      });
  }
}
